/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import localStorageAvailable from 'src/Presentation/utils/localStorageAvailable';
import { Session } from 'src/Domain/Models/Session';
import { HttpDashboard } from '../../../Services/Http';

export interface SessionDataSource {
  addSession(newSession: Partial<Session>): Promise<Session>;
  editSession(newSession: Partial<Session>): Promise<Session>;
  getSession(userID: string): Promise<Session>;
}

export class SessionDataSourceImpl implements SessionDataSource {
  async addSession(newSession: Partial<Session>): Promise<Session> {
    const storageAvailable = localStorageAvailable();
    const token = storageAvailable ? localStorage.getItem('accessToken') : '';
    const res = await HttpDashboard.post<Session>('/api/session/create', newSession, {
      headers: { authorization: `Bearer ${token}` },
    });
    return res.data;
  }

  async editSession(newSession: Partial<Session>): Promise<Session> {
    const storageAvailable = localStorageAvailable();
    const token = storageAvailable ? localStorage.getItem('accessToken') : '';
    const res = await HttpDashboard.put<Session>(
      `/api/session/update/${newSession.user}`,
      newSession,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  }

  async getSession(userID: string): Promise<Session> {
    const storageAvailable = localStorageAvailable();
    const token = storageAvailable ? localStorage.getItem('accessToken') : '';
    const res = await HttpDashboard.get<Session>(`/api/session/get/${userID}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    return res.data;
  }
}
