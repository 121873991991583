import useResponsive from 'src/Presentation/hooks/useResponsive';
import HeaderMainPageDesktop from './HeaderMainPageDesktop';
import HeaderMainPageMobile from './HeaderMainPageMobile';

interface HeaderMainPageProps {
  isNewSession: boolean;
  isLoading: boolean;
}

export default function HeaderMainPage(props: HeaderMainPageProps) {
  const { isNewSession, isLoading } = props;
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      {isMobile ? (
        <HeaderMainPageMobile isNewSession={isNewSession} isLoading={isLoading} />
      ) : (
        <HeaderMainPageDesktop isNewSession={isNewSession} isLoading={isLoading} />
      )}
    </>
  );
}
