import { useQuery } from '@tanstack/react-query';

import localStorageAvailable from 'src/Presentation/utils/localStorageAvailable';
import { Session } from '../Models/Session';
import { SessionRepositoryImpl } from '../../Data/Repositories/SessionRepositoryImpl';

export function useFetchSession(repository: SessionRepositoryImpl) {
  const storageAvailable = localStorageAvailable();
  const userIdAux = storageAvailable ? localStorage.getItem('userId') : '';

  const { data, isLoading } = useQuery<Session>({
    queryKey: ['session', userIdAux],
    queryFn: () => {
      if (!userIdAux) {
        return Promise.reject(new Error('User ID is null or undefined'));
      }
      return repository.getSession(userIdAux);
    },
    retry: false,
    enabled: !!userIdAux,
  });

  return {
    session: data,
    isLoading,
    userID: userIdAux,
  };
}
