import { Box, Button, Modal, Typography } from '@mui/material';
import useResponsive from 'src/Presentation/hooks/useResponsive';
import InfoIcon from '@mui/icons-material/Info';
import { Session } from 'src/Domain/Models/Session';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

interface SessionModalProps {
  open: boolean;
  session: Session | undefined;
  onContinue: () => void;
  onStartNew: () => void;
}

export default function SessionModal(props: SessionModalProps) {
  const { open, session, onContinue, onStartNew } = props;
  const isDesktop = useResponsive('up', 'lg');


  const isDisabled = () => {
    if(session === undefined || !session.mainPage.search.industry.label) return true
  return false 
  } 
  return (

    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: !isDesktop ? '90%' : style.width }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'success.main',
            color: 'white',
            px: 2,
            py: 1,
            borderRadius: 1,
            mb: 1,
          }}
        >
          <InfoIcon sx={{ mr: 1 }} />
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', fontSize: '1rem', lineHeight: 1.3 }}
          >
            Important
          </Typography>
        </Box>

        <Typography
          id="modal-session-description"
          sx={{
            mt: 2,
            px: 3,
            py: 2,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',

            lineHeight: 1.3,
          }}
          data-cy="main_page-text-important_alert"
        >
          Do you want to return to your last session, or start a new session?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, px: 3, pb: 2 }}>
          <Button
            variant="outlined"
          disabled={isDisabled()}
            color="success"
            onClick={onContinue}
            data-cy="main_page-button-continue_session"
          >
            Continue Session
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onStartNew}
            data-cy="main_page-button-new_session"
          >
            Start New Session
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
