import Logo from 'src/Presentation/components/logo';
import { Box } from '@mui/material';
import SearchTerms from './SearchTerms';
import AccountPopover from '../AccountPopover';
import SelectorSectorsAndIndustries from './SelectorSectorsAndIndustries';

interface HeaderMainPageMobileProps {
  isNewSession: boolean;
  isLoading: boolean;
}

export default function HeaderMainPageMobile(props: HeaderMainPageMobileProps) {
  const { isNewSession, isLoading } = props;
  return (
    <Box sx={{ mb: -2 }}>
      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' }}
        sx={{ ml: 2, mt: 2, mb: 4 }}
      >
        <Logo />

        <AccountPopover />
      </Box>
      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{ md: 'repeat(1, 1fr)' }}
        sx={{ ml: 2, mt: 2, mr: 2 }}
      >
        <SelectorSectorsAndIndustries isNewSession={isNewSession} isLoading={isLoading} />

        <SearchTerms />
      </Box>
    </Box>
  );
}
