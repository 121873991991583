import { Box, IconButton, Modal, Typography } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from 'src/Presentation/hooks/useResponsive';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};

interface IndustryModalProps {
  open: boolean;
  onClose: () => void;
}

export default function IndustryModal(props: IndustryModalProps) {
  const { open, onClose } = props;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: !isDesktop ? '90%' : style.width }}>
        <IconButton
          sx={{
            position: 'absolute',
            right: '8px',
            top: '12px',
            padding: '5px',
            fontSize: '1.25rem',
            color: 'white',
          }}
          aria-label="close"
          onClick={onClose}
          data-cy="main_page-button-close_important_alert"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'success.main',
            color: 'white',
            px: 2,
            py: 1,
            borderRadius: 1,
            mb: 1,
          }}
        >
          <InfoIcon sx={{ mr: 1 }} />
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', fontSize: '1rem', lineHeight: 1.3 }}
          >
            Important
          </Typography>
        </Box>

        <Typography
          id="modal-modal-description"
          sx={{
            mt: 2,
            mb: 2,
            px: 3,
            py: 1.5,
            fontSize: '0.95rem',
            lineHeight: 1.5,
            justifyContent: 'center',
          }}
          data-cy="main_page-text-important_alert"
        >
          In the Sector/Industry Menu that you&#39;ll see, select an Industry that you&#39;re
          interested in, and the tabs will be populated with articles and analyses relevant to that
          Industry.
        </Typography>
      </Box>
    </Modal>
  );
}
