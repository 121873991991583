import { Outlet, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import SessionModal from 'src/Presentation/pages/mainPage/components/SessionModal';
import { useContext, useState } from 'react';
import { SessionContext } from 'src/Presentation/context/SessionContext';
import { SessionDataSourceImpl } from 'src/Data/DataSources/Session/SessionDataSource';
import { SessionRepositoryImpl } from 'src/Data/Repositories/SessionRepositoryImpl';
import { useFetchSession } from 'src/Domain/UseCases/useFetchSession';
import { useMutation } from '@tanstack/react-query';
import { Session } from 'src/Domain/Models/Session';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import HeaderMainPage from './header/headerMainPage/HeaderMainPage';
import HeaderPage from './header/headerPage/HeaderPage';

interface DashboardLayoutProps {
  layout: string;
  title: string;
}

const sessionRepository = new SessionRepositoryImpl(new SessionDataSourceImpl());

export default function DashboardLayout(props: DashboardLayoutProps) {
  const { layout, title } = props;

  const { session: sessionState, setSession } = useContext(SessionContext);
  const { setSelectedTerms } = useContext(SelectedTermsContext);
  const navigate = useNavigate();
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(sessionState.showModalSession);
  const [isNewSession, setIsNewSession] = useState<boolean>(false);

  const { session, isLoading, userID } = useFetchSession(
    new SessionRepositoryImpl(new SessionDataSourceImpl())
  );

  const mutationCreatedSession = useMutation<Session, Error, Session>(
    (newSession) => sessionRepository.addSession(newSession),
    {
      onError: (error) => {
        console.log('errror creacion de sesion', error);
        console.error(error);
      },
    }
  );

  const mutationEditSession = useMutation<Session, Error, Session>(
    (newSession) => sessionRepository.editSession(newSession),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleContinueSession = () => {
    setSession((prevState) => ({
      ...prevState,
      showModalSession: false,

    }));
    setSelectedTerms(session?.mainPage.search.terms || []);
    setOpenSessionModal(false);
    if (!session) return;
    setSession(session);
    if (session?.emails.active) navigate('/dashboard/emails');
    if (session?.savedSearches.active) {
      navigate('/dashboard/saved-searches');
    }
  };

  const handleStartNewSession = () => {
    setIsNewSession(true);
    const search = {
      industry: {
        label: '',
        sector_ref: '',
        value: '',
        terms: [],
      },
      activeTab: undefined,
      subTabActive: undefined,
      terms: [],
      page: 1,
    };

    const newSession = {
      id: '',
      user: userID || '',
      mainPage: {
        search,
        active: true,
        folderSaving: '',
      },
      emails: {
        folderActive: '',
        active: false,
        page: 1,
      },
      savedSearches: {
        folderActive: '',
        active: false,
        page: 1,
      },
      showModalSession: false,
    };
    if (!session) {
      mutationCreatedSession.mutate(newSession);
    } else {
      mutationEditSession.mutate(newSession);
    }

    setSession(newSession);
    setOpenSessionModal(false);
  };


  return (
    <>
      {openSessionModal && (
        <SessionModal
          open={openSessionModal}
          session={session}
          onContinue={handleContinueSession}
          onStartNew={handleStartNewSession}
        />
      )}
      {!openSessionModal && (
        <Box sx={{ overflowY: 'hidden' }}>
          {layout === 'articles' ? (
            <HeaderMainPage isNewSession={isNewSession} isLoading={isLoading} />
          ) : (
            <HeaderPage title={title} />
          )}
          <Box>
            <Outlet />
          </Box>
        </Box>
      )}
    </>
  );
}
