import { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';
import { ItemSearchedTermsContext } from 'src/Domain/Models/Term';

interface termsByIndustry {
  industryID: string;
  label: string;
  terms: ItemSearchedTermsContext[];
}

export interface SessionType {
  id: string;
  user: string;
  mainPage: {
    search: {
      industry: {
        label: string;
        sector_ref: string;
        value: string;
        terms: termsByIndustry[];
      };
      terms: ItemSearchedTermsContext[];
      activeTab: string | undefined;
      subTabActive: string | undefined;
      page: number;
    };
    active: boolean;
    folderSaving: string;
  };
  emails: {
    folderActive: string;
    active: boolean;
    page: number;
  };
  savedSearches: {
    folderActive: string;
    active: boolean;
    page: number;
  };
  showModalSession: boolean;
}

interface SessionContextType {
  session: SessionType;
  setSession: Dispatch<SetStateAction<SessionType>>;
}

const defaultValue: SessionContextType = {
  session: {
    id: '',
    user: '',
    mainPage: {
      search: {
        industry: {
          label: '',
          sector_ref: '',
          value: '',
          terms: [],
        },
        terms: [],
        activeTab: undefined,
        subTabActive: undefined,
        page: 1,
      },
      active: false,
      folderSaving: '',
    },
    emails: {
      folderActive: '',
      active: false,
      page: 1,
    },
    savedSearches: {
      folderActive: '',
      active: false,
      page: 1,
    },
    showModalSession: true,
  },
  setSession: () => {},
};

export const SessionContext = createContext(defaultValue);

interface SessionContextProviderProps {
  children: React.ReactNode;
}

export const SessionContextProvider = (props: SessionContextProviderProps) => {
  const { children } = props;
  const [sessionState, setSessionState] = useState<SessionType>(defaultValue.session);

  const contextValue = useMemo(
    () => ({
      session: sessionState,
      setSession: setSessionState,
    }),
    [sessionState]
  );

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
