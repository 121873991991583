/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import { useFetchDetectTerm } from 'src/Domain/UseCases/useFetchDetectTerm';
import { TermDataSourceImpl } from 'src/Data/DataSources/Term/TermDataSource';
import { TermRepositoryImpl } from 'src/Data/Repositories/TermRepositoryImpl';
import { SessionContext } from 'src/Presentation/context/SessionContext';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import AlertNotificationContext from 'src/Presentation/context/AlertNotificationContext';
import AlertNotification from 'src/Presentation/pages/components/AlertNotification';

import { SessionDataSourceImpl } from 'src/Data/DataSources/Session/SessionDataSource';
import { SessionRepositoryImpl } from 'src/Data/Repositories/SessionRepositoryImpl';
import { Session } from 'src/Domain/Models/Session';
import { useMutation } from '@tanstack/react-query';

import TermsMatched from './TermsMatched';
import './style.css';

const sessionRepository = new SessionRepositoryImpl(new SessionDataSourceImpl());

export default function SearchTerms() {
  const { session, setSession } = useContext(SessionContext);
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);
  const { setAlertNotification } = useContext(AlertNotificationContext);
  const [inputText, setInputText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [termNotFound, setTermNotFound] = useState<boolean>(false);

  const { termDetected, isFetchDetectTermLoading } = useFetchDetectTerm(
    searchTerm,
    new TermRepositoryImpl(new TermDataSourceImpl())
  );

  const mutationEditSession = useMutation<Session, Error, Session>(
    (newSession) => sessionRepository.editSession(newSession),
    {
      onSuccess: () => {
        console.log('Session edited successfully');
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
    setTermNotFound(false);
  };

  const handleBackspaceDelete = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputText.length === 0 && (event.key === 'Backspace' || event.key === 'Delete')) {
      console.log('clean');
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = () => {
    if (inputText && !selectedTerms.some((term) => term.name === inputText)) {
      setSearchTerm(inputText);
    } else if (inputText) {
      setAlertNotification({
        showAlert: true,
        message: 'The term is already selected',
        severity: 'warning',
      });
    }
  };

  useEffect(() => {
    if (searchTerm !== '' && termDetected) {
      addTermToSelectedTerms();
    }
  }, [isFetchDetectTermLoading]);

  const addTermToSelectedTerms = () => {
    console.log("termDetected", termDetected);
    if (termDetected?.domain === 'industry_term') {
      if (!selectedTerms.some((term) => term.name === termDetected.stored_value))
        setSelectedTerms((prevState) => [
          ...prevState,
          { name: termDetected.stored_value, type: 'term' },
        ]);

      const updatedSession = {
        ...session,
        mainPage: {
          ...session.mainPage,
          search: {
            ...session.mainPage.search,
            terms: [
              ...session.mainPage.search.terms,
              { name: termDetected.stored_value, type: 'term' as const },
            ],
          },
        },
      };
      mutationEditSession.mutate(updatedSession);
      setSession(updatedSession);
    } else if (termDetected?.domain === 'company_name') {
      if (!selectedTerms.some((term) => term.name === termDetected.stored_value))
        setSelectedTerms((prevState) => [
          ...prevState,
          { name: termDetected.stored_value, type: 'company' },
        ]);

      const updatedSession = {
        ...session,
        mainPage: {
          ...session.mainPage,
          search: {
            ...session.mainPage.search,
            terms: [
              ...session.mainPage.search.terms,
              { name: termDetected.stored_value, type: 'company' as const },
            ],
          },
        },
      };
      mutationEditSession.mutate(updatedSession);
      setSession(updatedSession);
    } else if (termDetected?.domain === 'standard') {
      if (!selectedTerms.some((term) => term.name === termDetected.stored_value))
        setSelectedTerms((prevState) => [
          ...prevState,
          { name: termDetected.value, type: 'standard' },
        ]);

      const updatedSession = {
        ...session,
        mainPage: {
          ...session.mainPage,
          search: {
            ...session.mainPage.search,
            terms: [
              ...session.mainPage.search.terms,
              { name: termDetected.stored_value, type: 'standard' as const },
            ],
          },
        },
      };
      mutationEditSession.mutate(updatedSession);
      setSession(updatedSession);
    } else {
      setTermNotFound(true);
      setAlertNotification({
        showAlert: true,
        message: 'Term not found',
        severity: 'error',
      });
    }
    setInputText('');
    setSearchTerm('');
  };

  return (
    <>
      <AlertNotification top={1} />
      <Box sx={{ display: 'flex', mt: 1, ml: 0, mr: 2, flexDirection: 'column', flexGrow: 0 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Find terms"
          data-cy="input-term"
          sx={{ width: { xs: '100%', sm: '80%' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ padding: 0 }} position="end">
                <IconButton
                  type="button"
                  data-cy="button-input-term"
                  sx={{
                    cursor: inputText.length >= 2 ? 'pointer' : 'not-allowed',
                  }}
                  aria-label="Search"
                  disabled={inputText.length < 2}
                  onClick={handleClick}
                >
                  {termDetected && isFetchDetectTermLoading ? (
                    <CircularProgress size={24} />
                  ) : termNotFound ? (
                    <AddIcon />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          onKeyUp={handleBackspaceDelete}
          onKeyDown={handleEnterKeyPress}
          disabled={session.mainPage.search.industry.value === ''}
          value={inputText}
        />
        <TermsMatched />
      </Box>
    </>
  );
}
