/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Chip, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import { useContext } from 'react';
import { SessionContext } from 'src/Presentation/context/SessionContext';

export default function TermsMatched() {
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);
  const { setSession } = useContext(SessionContext);

  const handleDeleteChip = (chipToDelete: string) => () => {
    setSelectedTerms((prevState) => {
      const updatedTerms = prevState.filter((chip) => chip.name !== chipToDelete);
      setSession((prevState) => ({
        ...prevState,
        mainPage: {
          ...prevState.mainPage,
          search: {
            ...prevState.mainPage.search,
            terms: updatedTerms.map((term) =>({name: term.name, type: term.type})),
          },
        },
      }));
      return updatedTerms;
    });
  };

  return (
    <Box sx={{ mt: 2, overflow: 'hidden' }}>
      {selectedTerms.length > 0 && (
        <Stack
          data-cy="list-terms"
          direction="row"
          sx={{
            flexWrap: 'wrap',
            maxWidth: '100%',
          }}
        >
          {selectedTerms.map((term, index) => (
            <Chip
              key={index}
              label={term.name}
              color="primary"
              data-cy="selected-terms"
              onDelete={handleDeleteChip(term.name)}
              sx={{ mr: 1, mb: 1 }}
              deleteIcon={
                <IconButton size="small" aria-label="delete">
                  <CloseIcon sx={{ fontSize: 15 }} />
                </IconButton>
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
