import { Session } from 'src/Domain/Models/Session';
import { SessionRepository } from 'src/Domain/Repositories/SessionRepository';
import { SessionDataSource } from 'src/Data/DataSources/Session/SessionDataSource';

export class SessionRepositoryImpl implements SessionRepository {
  dataSource: SessionDataSource;

  constructor(datasource: SessionDataSource) {
    this.dataSource = datasource;
  }

  async addSession(newSession: Partial<Session>): Promise<Session> {
    return this.dataSource.addSession(newSession);
  }

  async editSession(newSession: Partial<Session>): Promise<Session> {
    return this.dataSource.editSession(newSession);
  }

  async getSession(userID: string): Promise<Session> {
    return this.dataSource.getSession(userID);
  }
}
