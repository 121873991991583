import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Box,
  Popover,
  Grid,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { CategorizationDataSourceImpl } from 'src/Data/DataSources/Categorization/CategorizationDataSource';
import { CategorizationRepositoryImpl } from 'src/Data/Repositories/CategorizationRepositoryImpl';
import { useFetchCategorizations } from 'src/Domain/UseCases/useFetchCategorization';
import { SessionContext } from 'src/Presentation/context/SessionContext';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import { ItemSearchedTermsContext } from 'src/Domain/Models/Term';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import IndustryModal from './IndustryModal';

interface SelectorSectorsAndIndustriesProps {
  isNewSession: boolean;
  isLoading: boolean;
}

export default function SelectorSectorsAndIndustries(props: SelectorSectorsAndIndustriesProps) {
  const { isNewSession, isLoading } = props;
  const { session, setSession } = useContext(SessionContext);
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popoverSize, setPopoverSize] = useState({ width: 0, height: 0 });
  const [openPopover, setOpenPopover] = useState(false);
  const [openIndustryModal, setOpenIndustryModal] = useState(false);

  const { categorizations, isFetchCategorizationsLoading } = useFetchCategorizations(
    new CategorizationRepositoryImpl(new CategorizationDataSourceImpl())
  );

  const sectors: any[] = [];
  let col = 1;
  let cont = 0;

  categorizations?.forEach((sector) => {
    if (cont === 3) {
      cont = 0;
      col += 1;
    }

    sectors.push({ label: sector.sector.name, value: sector.sector._id, column: col });

    sector.industries.forEach((industryItem) => {
      sectors.push({
        label: industryItem.name,
        value: industryItem._id,
        sector_ref: industryItem.sector_ref,
        enabled: industryItem.enabled,
        column: col,
      });
    });
    cont += 1;
  });

  const groupedByColumn: Record<
    string,
    { label: string; value: string; sector_ref?: string; enabled?: boolean }[]
  > = sectors.reduce(
    (
      acc: Record<
        string,
        { label: string; value: string; sector_ref?: string; enabled?: boolean }[]
      >,
      sector
    ) => {
      if (!acc[sector.column]) {
        acc[sector.column] = [];
      }
      acc[sector.column].push(sector);
      return acc;
    },
    {}
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenPopover(true);
    },
    [setSession]
  );

  const getTermsByIndustry = (industryId: string): ItemSearchedTermsContext[] => {
    const foundIndustry = session.mainPage.search.industry.terms.find(
      (term) => term.industryID === industryId
    );
    return foundIndustry ? foundIndustry.terms : [];
  };

  const updateSearch = (industryId: string, industryLabel: string, industrySectorRef: string) => {
    if (industryId !== session.mainPage.search.industry.value) {
      const termsByIndustry = getTermsByIndustry(industryId);
      const updatedSession = {
        ...session,
        mainPage: {
          ...session.mainPage,
          search: {
            ...session.mainPage.search,
            industry: {
              ...session.mainPage.search.industry,
              label: industryLabel,
              sector_ref: industrySectorRef,
              value: industryId,
              terms: [
                ...session.mainPage.search.industry.terms,
                {
                  industryID: session.mainPage.search.industry.value,
                  label: session.mainPage.search.industry.label,
                  terms: selectedTerms,
                },
              ],
            },
            page: 1,
            activeTab: undefined,
            terms: termsByIndustry.map((term) => ({name: term.name, type: term.type})),
          },
          active: true,
        },
        savedSearches: {
          ...session.savedSearches,
          active: false,
        },
        emails: {
          ...session.emails,
          active: false,
        },
      };

      setSession(updatedSession);

      setSelectedTerms(termsByIndustry);
    }
  };
  const resetSearch = () => {
    setSession((prevState) => ({
      ...prevState,
      mainPage: {
        ...prevState.mainPage,
        search: {
          ...prevState.mainPage.search,
          industry: {
            label: '',
            sector_ref: '',
            value: '',
            terms: [], // Mueve aquí `terms` si está relacionado con `industry`
          },
        },
      },
    }));
    setSelectedTerms([]);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpenPopover(false);
  }, []);

  const handleSelectOption = (
    option: string,
    enabled: boolean | undefined,
    value: string,
    sectorRef?: string
  ) => {
    if (enabled) {
      if (!value) resetSearch();
      else updateSearch(value, option, sectorRef!);
      setSelectedOption(option);
      handleClose();
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseIndustryModal = () => {
    setOpenIndustryModal(false);
    setAnchorEl(document.getElementById('input-id'));
    setOpenPopover(true);
  };

  useEffect(() => {
    const removeDuplicates = (): typeof session.mainPage.search.industry.terms => {
      const uniqueTerms: Record<string, typeof session.mainPage.search.industry.terms[0]> = {};
      session.mainPage.search.industry.terms.forEach((term) => {
        uniqueTerms[term.industryID] = term;
      });

      return Object.values(uniqueTerms);
    };

    setSession((prevState) => ({
      ...prevState,
      mainPage: {
        ...prevState.mainPage,
        search: {
          ...prevState.mainPage.search,
          industry: {
            ...prevState.mainPage.search.industry,
            terms: removeDuplicates(),
          },
        },
      },
    }));
  }, [session.mainPage.search.industry.value]);

  useEffect(() => {
    const updatePopoverSize = () => {
      setPopoverSize({
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.7,
      });
    };

    updatePopoverSize();

    window.addEventListener('resize', updatePopoverSize);

    return () => window.removeEventListener('resize', updatePopoverSize);
  }, []);

  useEffect(() => {
    if (session.mainPage.search.industry.label) {
      setSelectedOption(session.mainPage.search.industry.label);
    }
    if (isNewSession && !session.mainPage.search.industry.label) setOpenIndustryModal(true);
  }, [session.mainPage.search.industry.label]);

  const open = openPopover;
  const id = open ? 'simple-popover' : undefined;

  if (isFetchCategorizationsLoading || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 1 }}>
      {/* Input */}
      <TextField
        id="input-id"
        label="Sectors and Industries"
        value={selectedOption}
        onClick={handleOpen}
        fullWidth
        size="small"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">{open ? <ExpandLess /> : <ExpandMore />}</InputAdornment>
          ),
        }}
      />

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: popoverSize.width,
            height: popoverSize.height,
            overflow: 'auto',
          },
        }}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            {Object.entries(groupedByColumn).map(([columnKey, items], index, array) => (
              <Grid item xs={3} key={columnKey}>
                {items.map((item, idx) => (
                  <Box
                    key={idx}
                    onClick={() =>
                      handleSelectOption(item.label, item.enabled, item.value, item.sector_ref)
                    }
                    sx={{
                      cursor: item.enabled ? 'pointer' : 'default',
                      padding: item.sector_ref ? '0px' : '4px',
                      // eslint-disable-next-line no-nested-ternary
                      textDecoration: !item.sector_ref
                        ? 'underline'
                        : item.enabled
                        ? 'none'
                        : 'none',
                      fontWeight: item.enabled ? 'bold' : 'normal',
                      marginLeft: item.sector_ref ? '10px' : '0',
                      marginTop: item.sector_ref ? '0' : '10px',
                      '&:hover': item.enabled
                        ? {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                          }
                        : undefined,
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: item.enabled ? 'bold' : 'none' }}
                      variant="caption"
                    >
                      {item.label}
                    </Typography>
                  </Box>
                ))}

                {/* Renderiza el GICS en la última columna */}
                {index === array.length - 1 && (
                  <Box>
                    <Typography
                      variant="body2"
                      onClick={handleOpenModal}
                      sx={{
                        mt: 20,
                        cursor: 'pointer',
                        color: 'green',
                        fontWeight: 'bold',
                        textAlign: 'start',
                      }}
                    >
                      Global Industry Classification Standard (GICS)
                    </Typography>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
      {/* Modal para mostrar información */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: 3,
            maxWidth: '700px',
            textAlign: 'start',
          }}
        >
          <Typography variant="body2" paragraph sx={{ marginBottom: '16px' }}>
            The <b>Global Industry Classification Standard (GICS)</b> is an industry classification
            system developed by Morgan Stanley Capital International (MSCI) and Standard &
            Poor&apos;s (S&P) for use by the financial community. Our menu shows the GICS’s 2020 11
            sectors and 69 industries into which S&P has categorized many major <em>public </em>
            companies. The GICS is used as a basis for S&P and MSCI indexes used in the financial
            field. Each company is assigned to an industry by its principal business activity.
          </Typography>
          <Typography variant="body2" paragraph>
            We have used this classification system as a <em>general</em> guideline, including the
            companies MSCI and S&P have, but extending it to companies that are not classified
            within it. As companies in foreign stock exchanges are often not included (e.g., many
            Asian and European companies), nor are <em>private </em> companies. While the primary
            focus of the GICS is for financial analyses and tracking,
            <em>
              our focus is on the information shared about and discussed <u>within</u> industries
            </em>
            , which is often done using the same classifications (e.g., within the Automotive or
            Healthcare industries). In contrast, the <em>formal</em> use of the GICS for the MSCI
            and S&P indices (e.g., the S&P 500) is for trading or investment purposes.
          </Typography>
        </Box>
      </Modal>
      {openIndustryModal && (
        <IndustryModal open={openIndustryModal} onClose={handleCloseIndustryModal} />
      )}
    </Box>
  );
}
